<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import {swalHelper} from "@/helpers/swal-helper";

/**
 * Registration Access Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    FullCalendar
  },

  data() {
    return {
      submitted: false,

      calendarOptions: {
        eventContent: function(info) {
          return {
            html: info.event.title
          };
        },

        locale: this.$store.getters["translation/getCurrentLocaleOrFallback"],

        headerToolbar: {
          left: "prev,next",
          right: "prev,next",
          center: "title"
        },

        plugins: [
          dayGridPlugin,
          interactionPlugin,
          bootstrapPlugin
        ],

        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: [],
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.dateClicked,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
      },

      showModal: false,
      accessTime: {
        time: "",
        from: "",
        to: "",
        existing: false,
        repeat: false,
      },

      repeatDays: null
    };
  },

  validations: {
    accessTime: {
      from: {
        required,
        isTime: function(value) {
          const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return pattern.test(value);
        }
      },
      to: {
        required,
        isTime: function(value) {
          const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return pattern.test(value);
        }
      }
    }
  },

  methods: {

    getRepeatDayByDate(date) {
      if (!this.repeatDays) {
        return null
      }

      for (const repeatDay of this.repeatDays) {
        if (repeatDay.timesDate === date) {
          return repeatDay
        }
      }

      return null
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('registration-access.title'),
          active: true
        }
      ]
    },

    async loadTimes() {
      try {
        const result = await axios.get(`/registration-access/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        const data = result.data

        const list = Object.entries(data.accessTimes).map(([date, times]) => ({
          date,
          from: times.from,
          to: times.to
        }));

        this.repeatDays = Object.entries(data.repeatDays).map(([day, times]) => ({
          day,
          repeat: times.repeat,
          timesDate: times.timesDate
        }));

        const times = []

        let id = 0;
        for (const time of list) {
          const [day, month, year] = time.date.split('.');
          const [hours, minutes] = time.from.split(':');

          const dateTime = new Date(Number(year), Number(month - 1), Number(day), Number(hours), Number(minutes));

          const repeatDay = this.getRepeatDayByDate(time.date)

          const item = {
            time: time.date,
            from: time.from,
            to: time.to,

            id: ++id,
            title : time.from + " - " + time.to + (repeatDay && repeatDay.repeat ? " <strong>(Powtarzanie)</strong>" : ""),
            start: dateTime,
            className: "bg-success text-white"
          }

          times.push(item)
        }

        const calendarApi = this.$refs.calendar.getApi();
        calendarApi.removeAllEventSources();
        calendarApi.addEventSource(times);
        } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    dateClicked(newEventData) {
      const calendarApi = this.$refs.calendar.getApi();
      let event = calendarApi.getEvents().find(e => {
        const eventDate = e.start.toISOString().split('T')[0];
        return eventDate === newEventData.dateStr;
      });

      if (!event) {
        event = newEventData.event
      }

      if (event) {
        this.accessTime = event;

        try {
          let extendedProperties = event.extendedProps
          this.accessTime.time = extendedProperties.time
          this.accessTime.from = extendedProperties.from
          this.accessTime.to = extendedProperties.to
          this.accessTime.existing = true

          const repeatDay = this.getRepeatDayByDate(this.accessTime.time)
          if (repeatDay) {
            this.accessTime.repeat = repeatDay.repeat
          }
        } catch (error) {
          // do not handle
        }
      } else {
        let date = newEventData.date;
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();

        this.accessTime.time = `${day}.${month}.${year}`
        this.accessTime.existing = false
      }

      this.showModal = true;
    },

    hideModal() {
      this.submitted = false;
      this.showModal = false;
      this.accessTime = {
        time: "",
        from: "",
        to: "",
        existing: false
      }
    },

    createOrEditDate() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "date": this.accessTime.time,
        "fromHour": this.accessTime.from,
        "toHour": this.accessTime.to,
      });

      const repeatDay = this.getRepeatDayByDate(this.accessTime.time)

      axios.post(`/registration-access/edit-times`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        if (!repeatDay && !this.accessTime.repeat) {
          this.hideModal()
          this.loadTimes()
          Swal.fire("Sukces!", "Pomyślnie edytowano!", "success");
          return
        }

        this.editRepeatedDay()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    getDayOfWeek(dateString) {
      const dateParts = dateString.split(".");
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const year = parseInt(dateParts[2], 10);

      const date = new Date(year, month, day);
      const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

      return daysOfWeek[date.getDay()];
    },

    editRepeatedDay() {
      const json = JSON.stringify({
        "dayOfWeek": this.getDayOfWeek(this.accessTime.time),
        "repeat": this.accessTime.repeat,
        "timesDate": this.accessTime.time,
      });

      axios.post(`/registration-access/edit-repeat-days`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hideModal()
        this.loadTimes()
        Swal.fire("Sukces!", "Pomyślnie edytowano!", "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    deleteDate() {
      swalHelper.yesOrNo(() => {
        axios.delete(`/registration-access`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "date": this.accessTime.time
          }
        }).then(() => {
          this.hideModal()
          this.loadTimes()
          Swal.fire("Sukces!", "Pomyślnie usunięto!", "success");

        }).catch((error) => {
          errorCatcher.catchErrors(error)
        });
      })
    }
  },

  async mounted() {
    await this.loadTimes()
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('registration-access.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar ref="calendar" :options="calendarOptions"></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal"
             title="Edycja godzin możliwych do rejestracji"
             title-class="font-18"
             hide-footer
             @hide="hideModal"
             @esc="hideModal">
        <div class="form-group">
          <label>Od:</label>
          <input v-model="accessTime.from" type="text" class="form-control" :class="{ 'is-invalid': $v.accessTime.from.$error }" />
          <div v-if="!$v.accessTime.from.required" class="invalid-feedback">{{ $t('message.required') }}</div>
          <div v-if="!$v.accessTime.from.isTime" class="invalid-feedback">Niepoprawny format!</div>
        </div>

        <div class="form-group">
          <label>Do:</label>
          <input v-model="accessTime.to" type="text" class="form-control" :class="{ 'is-invalid': $v.accessTime.to.$error }" />
          <div v-if="!$v.accessTime.to.required" class="invalid-feedback">{{ $t('message.required') }}</div>
          <div v-if="!$v.accessTime.to.isTime" class="invalid-feedback">Niepoprawny format!</div>
        </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox mb-3">
          <input id="repeat" v-model="accessTime.repeat" type="checkbox" class="custom-control-input" />
          <label for="repeat" class="custom-control-label">Powtarzanie co tydzień</label>
        </div>
      </div>

        <div class="text-center pt-5 mt-3">
          <b-button class="ml-1" type="submit" variant="success" @click="createOrEditDate">Edytuj</b-button>

          <b-button v-if="accessTime.existing" variant="danger" class="mx-2" @click="deleteDate">Usuń</b-button>

          <b-button variant="secondary" @click="hideModal" :class="accessTime.existing ? '' : 'mx-2'">Anuluj</b-button>
        </div>
    </b-modal>
  </Layout>
</template>